
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: { TmButton, TmModal },
  setup() {
    const modal = ref()
    const hideModal = () => {
      modal.value.hideModal()
    }
    return {
      modal,
      hideModal,
    }
  },
})
